import { Fragment } from 'react';

import { getTheme } from 'src/utils/get-theme';
import { AppPreloader } from 'src/components/app-preloader';
import { ErrorState } from 'src/components/error-state';
import { AlumniCard } from 'src/features/alumni/components/alumni-card';
import { useFeatureFlags } from 'src/features/user';
import { useGetActiveBooking, useGetUpcomingBooking } from 'src/features/bookings';
import { ShowForCountry } from 'src/api/concierge';

import { useGetPromotions } from '../../hooks/use-get-promotions';

import { PromotionCard } from './parts/promotion-card';

export const PromotionCards = () => {
    const { data: allPromotionsUnsorted, isLoading, isError } = useGetPromotions();
    const { data: upcomingBooking } = useGetUpcomingBooking();
    const { data: activeBooking } = useGetActiveBooking();

    const { isHosTheme } = getTheme();

    const booking = activeBooking || upcomingBooking;

    const featureFlags = useFeatureFlags();

    if (isError) {
        return <ErrorState direction="column" title="Could not load exclusive offers" />;
    }

    if (isLoading) {
        return <AppPreloader css={{ height: '287px' }} background="default" />;
    }

    const webPromotionsSorted = allPromotionsUnsorted
        .filter((promotion) => promotion.display_on_web)
        .filter(
            (promotion) =>
                promotion.show_for_country === booking?.country_code ||
                promotion.show_for_country === ShowForCountry.ALL
        )
        .filter((promotion) => (isHosTheme ? promotion.show_on_hos : promotion.show_on_vs))
        .sort((a, b) => a.order - b.order);

    return (
        <Fragment>
            {!upcomingBooking && featureFlags.alumni_promo && <AlumniCard />}

            {webPromotionsSorted.map((promotion) => (
                <PromotionCard promotion={promotion} key={promotion.offer_id} />
            ))}
        </Fragment>
    );
};
